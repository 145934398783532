import React from 'react'
import { Link } from 'gatsby'
import {
  SITE_TITLE,
  LANGUAGE_ALIAS,
  ZUN_TWITTER,
  ORIGINAL_HP,
  ZUN_BLOG,
  TOUHOU_NEWS,
  intl,
} from 'src/constants'
import Banner from 'components/atoms/Banner'
import { Query } from 'react-apollo'
import { HEADER_BANNER_QUERY } from 'src/queries'
import { isDateAfterToday, isDateBeforeToday } from 'src/commonFunctions'
import logo from 'img/logo.png'
import LanguageSelection from 'components/LanguageSelection'
import Search from './GoogleCustomSearch'
import ClientOnly from './hooks/ClientOnly'
import twitterIcon from '../../resource/twitter_icon.svg'

const MAX_SCROLL_OFFSET = 100
const BASE_HEIGHT = {
  PC: 120,
  SP: 50,
}
const SP_WIDTH = 480

// スクロール位置に応じてだんだん小さくなっていって,
// MAX_SCROLL_OFFSETを超えたらロゴのサイズが半分の高さになる
const calcHeight = () => {
  const baseHeight = getBaseHeight()
  const offset = (window && window.pageYOffset) || 0
  if (offset === 0) return baseHeight

  const calc = offset >= MAX_SCROLL_OFFSET ? MAX_SCROLL_OFFSET : offset
  const subtract = (calc / MAX_SCROLL_OFFSET) * (baseHeight / 2)
  return baseHeight - subtract
}

const getBaseHeight = () => {
  const innerWidth = (window && window.innerWidth) || 0
  return innerWidth <= SP_WIDTH ? BASE_HEIGHT.SP : BASE_HEIGHT.PC
}

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: BASE_HEIGHT.PC,
    }
    this.prevOffsetHistory = []
  }

  componentDidMount() {
    this.setState({ height: getBaseHeight() })
    window.addEventListener('scroll', this.resizeLogoHeight.bind(this))
    window.addEventListener('resize', this.resizeLogoHeight.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.resizeLogoHeight.bind(this))
    window.removeEventListener('resize', this.resizeLogoHeight.bind(this))
  }

  resizeLogoHeight() {
    // header画像のサイズを変えると微妙に高さがずれるときがある。
    // そうした時に計算ループに入ってしまうことを防ぐために、後続の処理を止める判定を入れている
    if (this.prevOffsetHistory.length >= 2) {
      const isScrollVibe = this.prevOffsetHistory.some(
        v => v === window.pageYOffset
      )
      this.prevOffsetHistory.shift()
      if (isScrollVibe) {
        return
      }
    }
    const height = calcHeight()
    this.setState({ height })
    this.prevOffsetHistory.push(window.pageYOffset)
  }

  render() {
    return (
      <nav>
        <div className="main_header_links">
          <div className="beer_left">&#x1f37a;</div>
          <h2>{intl.getMessage('releated_sites')}</h2>
          <p className="original">
            <a href={ORIGINAL_HP} target="_brank" rel="nofollow">
              {intl.getMessage('original_hp')}
            </a>
          </p>
          <p className="original">
            <a href={ZUN_BLOG} target="_brank" rel="nofollow">
              {intl.getMessage('zun_blog')}
            </a>
          </p>
          <p className="original">
            <a href={ZUN_TWITTER} target="_brank" rel="nofollow">
              {intl.getMessage('zun_hp')}
            </a>
          </p>
          <p className="original">
            <a href={TOUHOU_NEWS} target="_brank" rel="nofollow">
              {intl.getMessage('touhou_news')}
            </a>
          </p>
          <div className="beer_right">&#x1f37a;</div>
        </div>
        <div className="main_header">
          <Link to="/">
            <img src={logo} alt={SITE_TITLE} />
          </Link>
          <a
            href="https://twitter.com/touhou_station"
            target="_brank"
            rel="nofollow"
          >
            <img className="twitter_icon" src={twitterIcon} />
          </a>
          <Query query={HEADER_BANNER_QUERY}>
            {({ data, loading }) => {
              if (loading) return <></>
              if (!data) return <></>

              // 広告は1つの投稿ですべてを設定するので[0]使用
              const { adbanner } = data.adbanners.nodes[0]
              const displayBanners = adbanner.banners.filter(
                banner =>
                  isDateAfterToday(banner.start) &&
                  isDateBeforeToday(banner.end)
              )
              const currentBanner = displayBanners[0]
              if (!currentBanner) return <></>
              return (
                <Banner
                  name={currentBanner.name}
                  link={currentBanner.link}
                  image={currentBanner.image}
                />
              )
            }}
          </Query>
          <div className="lang_selection">
            <LanguageSelection />
          </div>
          <ClientOnly className="search">
            <Search lang={LANGUAGE_ALIAS} />
          </ClientOnly>
        </div>
      </nav>
    )
  }
}
